import React from 'react'
import { Link } from 'theme-ui'


const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    textDecoration: `none`,
    ':visited': {
      color: `heading`
    },
    ':hover': {
      color: `#663399`
    },
    svg: {
      height: 24,
      ml: 2
    }
  }
}

const PoweredByGatsby = () => (
  <Link
    href='/'
    title='ZB'
    rel='noopener'
    sx={styles.link}
  > 
    Feito por ZB
  </Link>
)

export default PoweredByGatsby
